import { useRef, useState, useEffect } from "react"

const useDimensions = props => {
  const ref = useRef()
  const getSize = () =>
    ref && ref.current && ref.current.getBoundingClientRect().toJSON()
  const [dimensions, setDimensions] = useState({})

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getSize())
    }
    window.addEventListener("resize", handleResize)
    setDimensions(getSize())
    return () => window.removeEventListener("resize", handleResize)
    // eslint-disable-next-line
  }, [ref.current])

  return [ref, dimensions]
}

export default useDimensions
