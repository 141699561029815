import React from "react"
import { Parallax } from "react-scroll-parallax"

import {
  BodyCopyCapitalized,
  Box,
  BodyCopy,
  Paragon,
} from "../utils/styledComponents"
import theme from "../utils/theme"
import { getPhotoCourtesy } from '../utils/helpers'

const IntroHeader = props => {

  const { images, titles, description, isModalOpen } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pt={[3, 4]}
      opacity={isModalOpen ? 0 : 1}
    >
      <Box
        maxWidth="1000px"
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
        gridTemplateRows="1fr 1fr 1fr 1fr 1fr 1fr"
        width="100%"
        px={[3, 5]}
      >
        <Box gridColumn="1/4" gridRow="1/7">
          <Parallax y={[-15, 5]}>
            <ImageWithCaption
              textStyles={{ textAlign: 'left' }}
              alt={images[0].credit}
              src={images[0].image.asset.url}
            >
              {getPhotoCourtesy(images[0].credit)}
            </ImageWithCaption>
          </Parallax>
        </Box>
        <Box gridColumn="3/6" gridRow="2/5">
          <Parallax y={[-5, 5]}>
            <Paragon color="blue" fontSize={[4, 8]}>
              {`"${titles[0]}"`}
            </Paragon>
          </ Parallax>
        </Box>
      </Box>
      <Box width="100%" maxWidth={`${theme.maxWidth[0]}px`} css={{
                zIndex: 8,
              }}>
        <Parallax y={[-5, 10]}>
          <BodyCopyCapitalized
            p={[4]}
            fontSize={[2, 3]}
            lineHeight={["24px", "32px"]}
          >
            {description}
          </BodyCopyCapitalized>
        </Parallax>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        gridTemplateRows="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        width="100%"
        mb={[2, 3]}
        gridGap={[3, 4]}
      >
        <Box gridColumn="2/5" gridRow="3/10">
          <Parallax y={[10, -10]}>
            <ImageWithCaption
              textStyles={{ textAlign: "left" }}
              alt={images[1].credit}
              src={images[1].image.asset.url}
            >
              {getPhotoCourtesy(images[1].credit)}
            </ImageWithCaption>
        </Parallax >
        </Box>
        <Box
          gridColumn="6/9"
          gridRow="1/6"
        >
          <Parallax y={[-20, 20]}>
            <Box
              css={{
                zIndex: 8,
              }}
              minHeight={[3, 5]}
              backgroundColor="lightOrange"
              width="100%"
              height="100%"
            />
          </Parallax>
        </Box>
        <Box gridColumn="5/8" gridRow="2/8">
          <Parallax y={[-5, 5]}>
            <ImageWithCaption
              textStyles={{ textAlign: "left" }}
              alt={images[2].credit}
              src={images[2].image.asset.url}
            >
              {getPhotoCourtesy(images[2].credit)}
            </ImageWithCaption>
          </ Parallax>
        </Box>
        <Box gridColumn="5/9" gridRow="8/9">
          <Parallax y={[-10, 0]}>
          <Paragon color="blue" fontSize={[4, 7]} maxWidth={[4, 6]}>
            {`"${titles[1]}"`}
          </Paragon>
          </Parallax>
        </Box>
      </Box>
    </Box>
  )
}

const ImageWithCaption = props => {
  return (
    <Box>
      <img
        width="100%"
        src={props.src}
        alt={props.alt}
        css={{
          marginBottom: 0
        }}
      />
      <BodyCopy
        opacity={1}
        color="white"
        {...props.textStyles}
        fontSize={['6px', '8px']}
        css={{
          position: "absolute",
          bottom: 10,
          left: 8,
        }}
      >
        {props.children}
      </BodyCopy>
    </Box>
  )
}

export default IntroHeader
