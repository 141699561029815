import React from "react"

const defaultState = {
  popup: null,
  setPopup: () => {},
}

class PopupProvider extends React.Component {
  state = {
    popup: null,
  }
  setPopup = popup => {
    this.setState({ popup })
  }
  render() {
    const { children } = this.props
    const { popup } = this.state
    return (
      <PopupContext.Provider
        value={{
          popup,
          setPopup: this.setPopup,
        }}
      >
        {children}
      </PopupContext.Provider>
    )
  }
}

const PopupContext = React.createContext(defaultState)

export default PopupContext
export { PopupProvider }
