import React, { useContext } from "react"
import { graphql } from "gatsby"
import { ThemeProvider } from "emotion-theming"

import BaseLayout, { getNavLinks } from "../components/base"
import { Box, Brevier, DoublePica } from "../utils/styledComponents"
import IntroHeader from "../components/introHeader"
import RestaurantMap from "../components/restaurantMap"
import NavContext from "../utils/navContext"
import { PopupProvider } from "../utils/popupContext"
import RestaurantList from "../components/restaurantList"
import SEO from "../components/seo"
import theme from "../utils/theme"
import useDimensions from "../utils/useDimensions"

const CityPage = props => {
  const { data } = props
  const { allSanityCity, allSanityPage, sanityCity, allSanityRestaurant } = data

  const navLinks = getNavLinks(
    allSanityCity.edges.map(city => city.node.slugMeta) || [],
    allSanityPage.edges.map(page => page.node.slugMeta) || []
  )
  const allFilters = new Set(
    allSanityRestaurant.edges.map(
      ({ node }) => node.cuisine && node.cuisine[0].name
    )
  )
  const navConsumer = useContext(NavContext)
  const slugMeta = props.data.sanityCity.slugMeta
  const [restaurantList, setRestaurantList] = React.useState(
    allSanityRestaurant.edges
  )
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [filters, setFilters] = React.useState(allFilters)
  const [selectAllCuisines, setSelectAllCuisines] = React.useState(true)

  // turns on/off filter
  const toggleFilter = filter => {
    let newFilters = new Set([...filters])
    if (filters.has(filter)) {
      newFilters.delete(filter)
    } else {
      newFilters.add(filter)
    }
    setFilters(newFilters)

    const newRestaurantList = allSanityRestaurant.edges.filter(restaurant =>
      newFilters.has(restaurant.node.cuisine && restaurant.node.cuisine[0].name)
    )
    setRestaurantList(newRestaurantList)
  }

  // turns on "select all" or "clear" for all filters
  const toggleSelectAllCuisines = selectAll => {
    if (selectAll) {
      setFilters(new Set([...allFilters]))
      setRestaurantList(allSanityRestaurant.edges)
      setSelectAllCuisines(true)
    } else {
      setFilters(new Set())
      setRestaurantList([])
      setSelectAllCuisines(false)
    }
  }

  const [ref, { width }] = useDimensions()

  React.useEffect(() => {
    navConsumer.setCurrentPage(slugMeta.seoName)
    navConsumer.setNavLinks(navLinks)
    // eslint-disable-next-line
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <SEO title={slugMeta.seoName} />
      <BaseLayout
        backgroundColor="lightGreen"
        footerBackgroundColor="black"
        footerTextColor="lightOrange"
      >
        <PopupProvider>
          <IntroHeader
            images={sanityCity.images}
            titles={sanityCity.nicknames}
            description={sanityCity.description}
            isModalOpen={isModalOpen}
          />
          <Box
            backgroundColor="black"
            p={[4, 5]}
            display={{ _: "block", md: "none" }}
          >
            <Box>
              <Brevier color="neonGreen" lineHeight="20px">Map &#38; List</Brevier>
              <DoublePica color="white" fontSize={[4, 5, 6]}>Explore {sanityCity.name}</DoublePica>
            </Box>
          </Box>
          <Box display={{ _: "block", md: "none" }}>
            <Box opacity={isModalOpen ? 0 : 1}>
              <RestaurantMap
                latitude={sanityCity.location.lat}
                longitude={sanityCity.location.lng}
                restaurantList={restaurantList}
                height={theme.mapHeight[0]}
              />
            </Box>
          </Box>
          <Box
            backgroundColor="black"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              pt={[5]}
              px={[5]}
              maxWidth={`${theme.maxWidth[2]}px`}
              alignItems="flex-start"
              width="100%"
              display={{ _: "none", md: "block" }}
            >
              <Box>
                <Brevier color="neonGreen" lineHeight="20px">List &#38; Map</Brevier>
                <DoublePica color="white" fontSize={[3, 6]}>Explore {sanityCity.name}</DoublePica>
              </Box>
            </Box>
            <Box
              px={{ _: 0, md: 5 }}
              pb={{ _: 0, md: 5 }}
              pt={{ _: 0, md: 4 }}
              maxWidth={`${theme.maxWidth[2]}px`}
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              width="100%"
            >
              <Box
                flex="1"
                height={{ _: "unset", md: `${theme.mapHeight[1]}px` }}
                css={{ overflow: "scroll" }}
              >
                <RestaurantList
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  selectAllCuisines={selectAllCuisines}
                  toggleFilter={toggleFilter}
                  toggleSelectAllCuisines={toggleSelectAllCuisines}
                  allFilters={allFilters}
                  filters={filters}
                  restaurantList={restaurantList}
                />
              </Box>
              <Box flex="1" display={{ _: "none", md: "block" }} ref={ref}>
                <Box opacity={isModalOpen ? 0 : 1} width="100%">
                  <RestaurantMap
                    width={width}
                    height={theme.mapHeight[1]}
                    latitude={sanityCity.location.lat}
                    longitude={sanityCity.location.lng}
                    restaurantList={restaurantList}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </PopupProvider>
      </BaseLayout>
    </ThemeProvider>
  )
}

export default CityPage

export const query = graphql`
  query cityTemplateQuery($cityName: String!) {
    allSanityPage(
      filter: { slugMeta: { hidden: { ne: true }, seoName: {}, slug: {} } }
    ) {
      edges {
        node {
          slugMeta {
            slug
            seoName
          }
        }
      }
    }
    allSanityCity(filter: { slugMeta: { hidden: { ne: true } } }) {
      edges {
        node {
          name
          slugMeta {
            seoName
            slug
          }
        }
      }
    }
    sanityCity(name: { eq: $cityName }) {
      id
      images {
        credit
        image {
          asset {
            url
          }
        }
      }
      name
      nicknames
      slugMeta {
        seoName
        slug
      }
      description
      location {
        lat
        lng
      }
    }
    allSanityRestaurant(
      filter: { city: { name: { eq: $cityName } } }
      sort: { fields: cuisine___name, order: ASC }
    ) {
      totalCount
      edges {
        node {
          name
          mustOrder
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          cuisine {
            name
          }
          description
          id
          location {
            lat
            lng
          }
          address {
            street_1
            city
            zipcode
            state
          }
          website
        }
      }
    }
  }
`
