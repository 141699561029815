import React, { useState } from "react"
import MapGL, { Marker, NavigationControl, Popup } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"

import { Box, Brevier, Paragon, Pica } from "../utils/styledComponents"
import PopupContext from "../utils/popupContext"
import { getRestaurantFromList } from "../utils/helpers"

const TOKEN =
  process.env.MAP_BOX_API ||
  "pk.eyJ1IjoiZmxvZmxvbGl1IiwiYSI6ImNrMHlxZ3IzMjBpMzQzYnA4NThkZ2JjMzIifQ.GJX78x4xOjDQJl0mzHgW1g"

const navStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  padding: "10px",
}

const Pin = props => {
  return (
    <Box onClick={props.onClick} backgroundColor="black" borderRadius="50%">
      <Paragon
        fontSize={[2]}
        px={[2]}
        py={[1]}
        lineHeight="18px"
        color="lightGreen"
      >
        N
      </Paragon>
    </Box>
  )
}

const renderAddress = address => {
  if (address) {
    const { street_1, city, state, zipcode } = address
    return (
      <Box mt={[1]}>
        {street_1 && <Pica fontSize={[2]}>{street_1}</Pica>}
        {city && state && zipcode && (
          <Pica fontSize={[1, 2]}>{`${city}, ${state} ${zipcode}`}</Pica>
        )}
      </Box>
    )
  }
}

const renderWebsite = website => {
  if (website) {
    return (
      <Box mt={[2]}>
        <a href={website} target="_blank" rel="noopener noreferrer">
          <Pica color="black" fontSize={[2]}>
            Website &#8250;
          </Pica>
        </a>
      </Box>
    )
  }
}

const renderPopup = (restaurantList, popup, setPopup) => {
  const restaurant = getRestaurantFromList(restaurantList, popup)
  if (popup && restaurant) {
    const { location, name, cuisine, address, website } = restaurant
    return (
      <Popup
        tipSize={5}
        anchor="bottom-right"
        longitude={location.lng}
        latitude={location.lat}
        onClose={() => setPopup(null)}
        closeOnClick={true}
      >
        <Box pt={3} minWidth="100px" px={[2, 3]}>
          <Pica fontSize={[3]}>{name}</Pica>
          <Box mt={[2]} mb={[3]}>
            <Brevier fontSize={[1]} color="lightGray" lineHeight="16px">
              {cuisine && cuisine[0] && cuisine[0].name}
            </Brevier>
          </Box>
          {renderAddress(address)}
          {renderWebsite(website)}
        </Box>
      </Popup>
    )
  } else {
    return null
  }
}

const RestaurantMap = props => {
  const { latitude, longitude, restaurantList, height } = props
  const [viewport, setViewPort] = useState({
    latitude,
    longitude,
    zoom: 12,
  })
  const popupConsumer = React.useContext(PopupContext)
  const { popup, setPopup } = popupConsumer

  const onViewportChange = viewport => {
    const { height, width, ...etc } = viewport
    setViewPort({ ...etc })
  }

  return (
    <Box mx="0" width="100%">
      <MapGL
        width="100%"
        height={`${height}px`}
        {...viewport}
        mapboxApiAccessToken={TOKEN}
        mapStyle="mapbox://styles/flofloliu/ck0zliy8x04xg1cqon3uux5yf"
        onViewportChange={onViewportChange}
      >
        <div className="nav" style={navStyle}>
          <NavigationControl />
        </div>
        {restaurantList &&
          restaurantList.map((item, index) => {
            const { location, name } = item.node
            return (
              <Marker
                key={`map_marker_${index}`}
                longitude={location.lng}
                latitude={location.lat}
              >
                <Pin onClick={() => setPopup(name)} />
              </Marker>
            )
          })}
        {renderPopup(restaurantList, popup, setPopup)}
      </MapGL>
    </Box>
  )
}

export default RestaurantMap
