import React from "react"
import ReactModal from "react-modal"

import {
  Box,
  BodyCopy,
  Brevier,
  Bullet,
  DoublePica,
  Pica,
} from "../utils/styledComponents"
import PopupContext from "../utils/popupContext"
import theme from "../utils/theme"

const RestaurantList = props => {
  const {
    isModalOpen,
    setIsModalOpen,
    selectAllCuisines,
    toggleFilter,
    toggleSelectAllCuisines,
    allFilters,
    filters,
    restaurantList,
  } = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      px={[4, 5]}
      py={[4]}
      backgroundColor="white"
      minHeight="100%"
    >
      <FilterModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectAllCuisines={selectAllCuisines}
        toggleFilter={toggleFilter}
        toggleSelectAllCuisines={toggleSelectAllCuisines}
        allFilters={allFilters}
        filters={filters}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        maxWidth={`${theme.maxWidth[1]}px`}
        mt={[3, 4]}
      >
        <Box>
          <Brevier color="black">{`${restaurantList.length} nibbles`}</Brevier>
        </Box>
        <Box
          css={{
            cursor: "pointer",
          }}
        >
          <Brevier
            color="black"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >{`filter`}</Brevier>
        </Box>
      </Box>
      <Box py={[0, 1]} maxWidth={`${theme.maxWidth[1]}px`} width="100%">
        {restaurantList && restaurantList.length > 0 ? (
          restaurantList.map(({ node }, index) => {
            return (
              <RestaurantListItem
                key={`restaurant_list_item_${index}`}
                node={node}
                index={index}
              />
            )
          })
        ) : (
          <RestaurantListItem />
        )}
      </Box>
    </Box>
  )
}

const FilterModal = props => {
  const {
    allFilters,
    filters,
    isModalOpen,
    selectAllCuisines,
    setIsModalOpen,
    toggleFilter,
    toggleSelectAllCuisines,
  } = props
  return (
    <ReactModal
      ariaHideApp={false}
      style={{
        overlay: {
          position: "fixed",
        },
        content: {
          backgroundColor: theme.colors.white,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
        },
      }}
      isOpen={isModalOpen}
    >
      <Box css={{ zIndex: 9999 }}>
        <Box
          css={{
            cursor: "pointer",
          }}
          display="flex"
          justifyContent="flex-start"
          width="100%"
          onClick={() => setIsModalOpen(false)}
          mb={[2]}
        >
          <Brevier color="black" fontSize={[5]}>
            &#10005;
          </Brevier>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            width="100%"
            maxWidth={`${theme.maxWidth[1]}px`}
          >
            <Box width="100%">
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                css={{
                  cursor: "pointer",
                }}
                my={[2, 3]}
              >
                <Brevier>Filter By Cuisine</Brevier>
                <Brevier
                  onClick={() => toggleSelectAllCuisines(!selectAllCuisines)}
                  color="darkGray"
                >
                  {selectAllCuisines ? "Clear" : "Select All"}
                </Brevier>
              </Box>
              {[...allFilters].map(filter => {
                return (
                  <Box
                    key={`filter_${filter}`}
                    css={{
                      cursor: "pointer",
                    }}
                    my={[2, 3]}
                  >
                    <Bullet
                      bulletColor={
                        filters.has(filter)
                          ? theme.colors.neonGreen
                          : "transparent"
                      }
                      bulletBorder={`1px solid ${theme.colors.black}`}
                      onClick={() => toggleFilter(filter)}
                    >
                      <DoublePica>{filter}</DoublePica>
                    </Bullet>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </ReactModal>
  )
}

const RestaurantListItem = props => {
  const { index, node } = props
  let content = <Brevier color="black">No nibbles to display.</Brevier>
  const [showDetails, setShowDetails] = React.useState(false)

  const popupConsumer = React.useContext(PopupContext)
  const { setPopup } = popupConsumer

  if (node) {
    const haveDetails =
      (node.mustOrder && node.mustOrder.length > 0) || node.address
    content = (
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          onClick={() => {
            if (haveDetails) {
              setShowDetails(!showDetails)
            }
          }}
          css={{ cursor: "pointer" }}
        >
          <DoublePica>{node.name}</DoublePica>
          {haveDetails && (
            <BodyCopy
              fontSize={7}
              color="gray"
              css={{ cursor: "pointer" }}
              lineHeight="20px"
            >
              &#8964;
            </BodyCopy>
          )}
        </Box>
        <Box mt={[3]} mb={[3]}>
          <Brevier color="lightGray" lineHeight="16px">{node.cuisine && node.cuisine[0].name}</Brevier>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection="column"
          mt={[2]}
        >
          <Box>
            <Pica fontSize={[2, 3]}>
              {node.description && node.description}
            </Pica>
          </Box>
          {showDetails && (
            <Box
              width="100%"
              mt={[3]}
              display="flex"
              justifyContent="space-between"
            >
              {node.mustOrder.length > 0 && (
                <Box flex="3" pr={[3, 4]}>
                  {renderMustOrderItems(node.mustOrder)}
                </Box>
              )}
              {node.address && (
                <Box flex="2">
                  <Brevier color="lightGray">Address</Brevier>
                  {node.address.street_1 && (
                    <Pica fontSize={[2, 3]}>{node.address.street_1}</Pica>
                  )}
                  {node.address.city &&
                    node.address.state &&
                    node.address.zipcode && (
                      <Pica
                        fontSize={[2, 3]}
                      >{`${node.address.city}, ${node.address.state} ${node.address.zipcode}`}</Pica>
                    )}
                  <Box mt={[2]} css={{ cursor: "pointer" }}>
                    <Brevier
                      color="lightGray"
                      onClick={() => setPopup(node.name)}
                    >
                      Show on Map &#8250;
                    </Brevier>
                  </Box>
                  {node.website && (
                    <Box>
                      <a
                        href={node.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Brevier color="lightGray">Website &#8250;</Brevier>
                      </a>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    )
  }
  return (
    <Box
      key={`restaurant_${index}`}
      borderColor={theme.colors.gray}
      borderBottomStyle={index === undefined ? "" : "solid"}
      borderWidth={1}
      py={4}
      borderTopStyle={index === 0 || !index ? "solid" : ""}
    >
      {content}
    </Box>
  )
}

const renderMustOrderItems = mustOrderItems => {
  return (
    <React.Fragment>
      <Brevier color="lightGray">Must Order</Brevier>
      {mustOrderItems.map((item, index) => {
        return (
          <Box
            key={`must_order_${index}`}
            display="flex"
            alignItems="flex-start"
          >
            <Pica fontSize={[2, 3]} pr={[1, 2]}>
              -
            </Pica>
            <Pica fontSize={[2, 3]}>{item}</Pica>
          </Box>
        )
      })}
    </React.Fragment>
  )
}

export default RestaurantList
